<template>
  <div class="main">
    <div class="topline"></div>
    <div class="content">
      <div class="footer-left">
        <img
          src="../../../assets/image/登录页/科研人logo黑.png"
          alt=""
        >
        <div class="text">
          <div class="top">
            <span>
              <router-link :to="{path:'/pc/content/aboutUs'}">关于我们</router-link>
            </span>
            <span>
              <router-link :to="{path:'/pc/content/suggest'}">问题&建议</router-link>
            </span>
            <span>
              <router-link :to="{path:'/pc/content/declarationAndTerms'}">声明&条款</router-link>
            </span>
          </div>
          <div class="bottom">
            <span>科研人（www.keyanpro.com）版权所有</span>
          </div>
        </div>
      </div>
      <div class="footer-right">
        <div class="qr-code">
          <div class="image">
            <img
              src="../../../assets/image/关于我们_slices/1234.png"
              alt=""
            >
          </div>
          <div class="text">登录小程序</div>
        </div>
        <div class="qr-code">
          <div class="image">
            <img
              src="../../../assets/image/关注公众号.png"
              alt=""
            >
          </div>
          <div class="text">
            关注公众号
          </div>
        </div>
        <div class="qr-code">
          <div class="image">
            <img
              src="../../../assets/image/推荐给好友.png"
              alt=""
            >
          </div>
          <div class="text">推荐给好友</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.main {
  .topline {
    height: 7px;
    background-color: #5e068c;
  }
  width: 1920px !important;
  background-color: #0a0a0a !important;
  height: 110px;
  .content {
    width: 1200px;
    height: calc(100% - 7px);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .footer-left {
      display: flex;
      justify-content: left;
      align-items: center;
      img {
        margin-right: 53px;
        height: 78px;
      }
      .text {
        .top {
          display: flex;
          justify-content: left;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 21px;
          color: #a1a1a1;
          opacity: 1;
          margin-bottom: 10px;
          a {
            color: #a1a1a1;
          }
          span {
            margin-right: 30px;
          }
        }
        .bottom {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 21px;
          color: #4f4f4f;
          opacity: 1;
        }
      }
    }
    .footer-right {
      display: flex;
      justify-content: left;
      align-items: center;
      .qr-code {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: center;
        margin-left: 35px;
        .image {
          img {
            width: 56px;
            height: 56px;
          }
        }
        .text {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 21px;
          color: #a1a1a1;
          opacity: 1;
        }
      }
    }
  }
}
</style>
